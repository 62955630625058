import { useEffect } from 'react';

const useBroadcastChannel = (eventName, callback) => {
  useEffect(() => {
    const channel = new BroadcastChannel(eventName);
    const handleMessage = (event) => {
      if (typeof callback === 'function') {
        callback(event.data);
      }
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [eventName, callback]);
};

export default useBroadcastChannel;