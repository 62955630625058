import { theme } from 'tailwind.config';
import { brandName } from '@helpers/constants';

export const freshChatHostUrl = 'https://wchat.in.freshchat.com';

export const freshChatWidgetId = process.env.NEXT_PUBLIC_FRESHCHAT_WIdGET_ID;

export const FRESHCHAT_SCRIPTS = {
    preChatForm: 'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js',
    widgetScript: '//in.fw-cdn.com/30185002/117542.js',
    jQueryScript: 'https://code.jquery.com/jquery-3.6.0.min.js',
};

export const freshChatSizeClass = 'fc-widget-small';

export const preChatTemplate = {
    mainbgColor: theme?.colors?.primary?.[500],
    maintxColor: theme?.colors?.basicWhite,
    heading: brandName,
    textBanner:
        "We can't wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.",
    SubmitLabel: 'Start Chat',
    fields: {
        field4: {
            type: 'phone',
            label: 'Phone',
            fieldId: 'phone',
            required: 'yes',
            error: 'Please Enter a valid Phone Number',
        },
        field2: {
            type: 'name',
            label: 'Name',
            fieldId: 'firstname',
            required: 'yes',
            error: 'Please Enter a valid Name',
        },
        field3: {
            type: 'email',
            label: 'Email',
            fieldId: 'email',
            required: 'yes',
            error: 'Please Enter a valid Email',
        },
    },
};
