import { getAuth } from '@services/identity.service';
import {
    FRESHCHAT_SCRIPTS,
    freshChatSizeClass,
    freshChatWidgetId,
    preChatTemplate,
} from '@components/FreshChat/helper';

// Show and hide pre chat form and set user properties
async function setFreshChatUserProperties() {
    const auth = getAuth();
    if(!auth && window.fcPreChatform && !window.fcPreChatform.preChatFormUserData) {
        // Display pre chat form when user is not logged in.
        setTimeout(() => {
            document.querySelector(".chat-fc-form-outer")?.style?.setProperty("visibility", "visible", "important");
        }, 100)
    } else if (window.fcPreChatform) {
        // Display chatbot and hide pre chat form when user is logged in.
        setTimeout(() => {
            document.querySelector(".chat-fc-form-outer")?.style?.setProperty("visibility", "hidden", "important");
            document.querySelector("." + window.fcSettings.config.cssNames.widget + " iframe")?.style?.setProperty("visibility", "visible", "important")
        }, 100)
    }

    if (auth) {
        // Set new user detail
        try{
            await window.fcWidget.setExternalId(auth.freshChatId);
            await window.fcWidget.user.setProperties({
                cf_jiraaf_id: auth.id,
            });
        } catch (error) {
            console.error('setFreshChatUserProperties', error);
        }
    }
}

// Call set user properties on open of widget
export const setFreshChatWidget = () => {
    if (freshChatWidgetId !== 'FC-dummy') {
        if (window.fcWidget) {
            // Freshchat is loaded, seeting user properties on open of widget
            window.fcWidget.on('widget:opened', async function () {
                await setFreshChatUserProperties();
            });
            window.fcWidget.on('widget:destroyed', async function () {
                try{
                    await window.fcWidget.setExternalId(null);
                    window.fcWidget.user.setProperties({
                        cf_jiraaf_id: null,
                    });
                } catch (error) {
                    console.error('setFreshChatWidget, widget:destroyed', error);
                }
            });
        } else {
            // Freshchat is not loaded, retry in a short time
            setTimeout(setFreshChatWidget, 500);
        }
    }
};

// setting value on login, so that if sdk is already open still user data will set
export const setFreshChatPropertiesOnLogin = async () => {
    if (freshChatWidgetId !== 'FC-dummy') {
        if (window.fcWidget) {
            // Freshchat is loaded
            window.fcWidget.close();
            window.fcWidget.user.clear();
        } else {
            // Freshchat is not loaded, retry in a short time
            setTimeout(setFreshChatPropertiesOnLogin, 500);
        }
    }
};

// Load Scripts to body
const loadScript = (src, widgetId = null) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        if (widgetId) {
            script.setAttribute('chat', 'true');
            script.setAttribute('widgetId', widgetId);
        }
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
};


const loadFreshChatScripts = async () => {
    const auth = getAuth();
    const isLoggedOutPage = window.location.pathname === '/logout';
    try {
        if (!auth || isLoggedOutPage) {
            // Pre chat form script
            await loadScript(FRESHCHAT_SCRIPTS.preChatForm);
            // Setting pre chat form configurations
            window.fcSettings = {
                config: {
                    cssNames: {
                        // Widget className should be same as class specified to chatbout outer container class
                        widget: freshChatSizeClass,
                        expanded: 'custom_fc_expanded',
                    },
                },
                onInit: () => {
                    fcPreChatform.fcWidgetInit(preChatTemplate);
                },
            };
        }
        // Load jquery script, requires to load chatbot
        await loadScript(FRESHCHAT_SCRIPTS.jQueryScript);
        // Load chatbot script with specific widgetId
        await loadScript(FRESHCHAT_SCRIPTS.widgetScript, freshChatWidgetId);
    } catch (error) {
        console.error('Error loading scripts:', error);
    }
};

// Load FreshChat and pre chat form scripts with widget id
export const loadFreshChat = async () => {
    if (freshChatWidgetId !== 'FC-dummy' && !window.fcWidget) {
        await loadFreshChatScripts();
    }
};

// Clear user data and show pre chat form
export const clearFreshChatUserData = (isCalledFromLogoutPage = false) => {
    if (window.fcWidget) {
        // Freshchat is loaded
        document.querySelector("." + freshChatSizeClass + " iframe")?.style?.setProperty("visibility", "hidden", "important")
        new Promise((resolve, reject) => {
            try {
                window.fcWidget.user.clear();
                window.fcWidget.destroy();
                if (window.fcPreChatform && isCalledFromLogoutPage) {
                    // Display pre chat form and hide chat bot
                    setTimeout(() => {
                        document.querySelector(".chat-fc-form-outer")?.style?.setProperty("visibility", "visible", "important");
                        resolve();
                    }, 100)
                }else{
                    resolve();
                }
            } catch (error) {
              reject();
            }
        }).then(() => {
            setTimeout(() => {
                document.querySelector("." + freshChatSizeClass + " iframe")?.style?.setProperty("visibility", "visible", "important")
            }, 1000);
        })
    } else {
        // Freshchat is not loaded, retry in a short time
        setTimeout(clearFreshChatUserData, 500);
    }
};

// Hide Chatbot
export const hideFreshchat = () => {
    const delaySetup = () => {
        if (window.fcWidget) {
            // Freshchat is already loaded, set up event listener
            window.fcWidget.hide();
        } else {
            // Freshchat is not loaded, retry in a short time
            setTimeout(delaySetup, 50);
        }
    };
    if (freshChatWidgetId !== 'FC-dummy') delaySetup();
};
